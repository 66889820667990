import { environment } from './../environments/environment';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { WebComponentsConfig, WEB_COMPONENTS_CONFIG } from '@codeorbit/webcomponents';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { IMAGE_CONFIG } from '@angular/common';
// import { provideLocaleData } from '@angular/common';
// import localeDe from '@angular/common/locales/de';

function getWebComponentsConfig(): WebComponentsConfig {
  return {
    template: 'assan',
    storyBlokContentVersion: environment.production ? 'published' : 'draft',
    storyBlokToken: environment.storyBlokToken,
    notFoundUrl: '/notfound',
    socialMediaApiEndpoint: 'funcsocialmediaintegration-prod.azurewebsites.net',
    debug: environment.production ? null : {}
  };
}

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling({
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,  inMemoryScrollingFeature),
    provideHttpClient(withFetch()),
    provideClientHydration(),
    { provide: WEB_COMPONENTS_CONFIG, useFactory: getWebComponentsConfig },
    {
      // disableImageSizeWarning set to true to avoid warnings in the console
      // smaller image sizes have to be provided by storyblok
      // for more infos see: https://angular.io/errors/NG0913
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      }
    },
    // importProvidersFrom([
    //   provideLocaleData(localeDe)
    // ])
  ]
};


